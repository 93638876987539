import { Box, Flex, Icon, theme } from '@energiebespaarders/symbols';
import { CaretRight } from '@energiebespaarders/symbols/icons/line';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Scroll from 'react-scroll';
import styled from 'styled-components';
import { dummy_dummy_content_AnchorLinkRecord } from '~/types/generated/cms/dummy';
import BlockContainer from '../DatoCms/BlockContainer';

interface Props {
  block: dummy_dummy_content_AnchorLinkRecord;
}

const Heading = styled(Box)`
  font-size: ${theme.type.scale[3]};
  font-weight: 600;
`;

export const constructUrlWithAnchor = (currentPath: string, anchor: string) => {
  const baseUrl = currentPath.split('#')[0];

  return `${baseUrl}#${anchor}`;
};

const ScrollLink = Scroll.Link;
const StyledScrollLink = styled(ScrollLink)`
  color: black;
  width: 100%;
  height: 100%;
  display: flex;
`;

const AnchorLinks: React.FC<Props> = ({ block }) => {
  const [hasActiveQuestion, setHasActiveQuestion] = useState(false);
  const router = useRouter();
  const currentPath = router.asPath;

  const scroller = Scroll.scroller;

  // When a user clicks on an Anchor link it updates the URL so if they send it to someone the page can scroll directly to that anchor.
  // This checks if an anchor exists in the URL and if it does it scrolls to it.
  useEffect(() => {
    const currentAnchorId = currentPath.split('#')[1];
    const currentAnchor = block.anchors.find(b => b.id === currentAnchorId)?.anchor;

    if (currentAnchor && !hasActiveQuestion) {
      scroller.scrollTo(currentAnchor, {
        duration: 1500,
        delay: 500,
        smooth: true,
        offset: -70,
      });

      setHasActiveQuestion(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath, hasActiveQuestion]);

  const handleAnchorClick = (anchor: string) => {
    setHasActiveQuestion(true);
    void router.push(`${constructUrlWithAnchor(currentPath, anchor)}`, undefined, {
      shallow: true,
    });
  };

  return (
    <BlockContainer size="md">
      <Flex flexDirection="column" justifyContent="center" width={1}>
        <Heading mb={2}>Direct naar</Heading>
        <Flex flexWrap="wrap" mx={-2}>
          {block.anchors.map(anchor => (
            <Flex py={2} px={2} key={anchor.id} width={[1, 1, 1 / 2, 1 / 2]}>
              <StyledScrollLink
                to={anchor.anchor!}
                smooth
                isDynamic
                duration={500}
                onClick={() => handleAnchorClick(anchor.anchor!)}
              >
                <Icon icon={CaretRight} />
                <Box ml={2}>{anchor.label}</Box>
              </StyledScrollLink>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </BlockContainer>
  );
};

export default AnchorLinks;
