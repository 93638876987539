import { Accordion, Box, Flex } from '@energiebespaarders/symbols';
import styled from 'styled-components';
import { dummy_dummy_content_FaqDropdownRecord } from '~/types/generated/cms/dummy';
import FaqGoogleStructuredData from 'src/datoCMS/FaqGoogleStructuredData';
import BlockContainer from '../DatoCms/BlockContainer';
import TypedStructuredText from '../DatoCms/TypedStructuredText';
import DatoButton from './DatoButton';

interface Props {
  block: dummy_dummy_content_FaqDropdownRecord;
}

const Heading = styled.h4`
  margin-bottom: 12px;
  margin-left: 12px;
`;

const StyledAccordion = styled(Accordion)`
  border-bottom: 1px solid lightGrey;
  border-radius: 0px;
`;

const FaqDropdown: React.FC<Props> = ({ block }) => {
  return (
    <>
      <FaqGoogleStructuredData faqItems={block.items} />
      <BlockContainer size="md" py={[3, 4, 5]} removeHorizontalPadding>
        <Flex flexDirection="column">
          <Heading>{block.title}</Heading>
          {block.items.map(item => (
            <StyledAccordion
              style={{ borderBottom: '1px solid lightGrey', borderRadius: 0 }}
              key={item.id}
              contentBgColor="none"
              iconColor="green"
              indicator="plus"
              title={item.question!}
            >
              <TypedStructuredText data={item.answer} />
            </StyledAccordion>
          ))}

          {block.button.length > 0 && (
            <Box mt={6} mb={2}>
              <DatoButton block={block.button[0]} customStyle={{ margin: 0 }} />
            </Box>
          )}
        </Flex>
      </BlockContainer>
    </>
  );
};

export default FaqDropdown;
