import { useIsMobile } from '@energiebespaarders/hooks';
import { Box, Container, Flex } from '@energiebespaarders/symbols';
import { Element as Anchor } from 'react-scroll';
import { dummy_dummy_content_ProductPageContentRecord } from '~/types/generated/cms/dummy';
import { BlockRenderer, BlockRendererOverrides, CMSBlock } from './BlockRenderer';

interface Props {
  block: dummy_dummy_content_ProductPageContentRecord;
  overrides?: BlockRendererOverrides;
}

/** Determines one or two-column behavior */
const ContentLayout: React.FC<Props> = ({ block, overrides }) => {
  const isMobile = useIsMobile();
  const hasTwoItems = block.content.length === 2;
  const hasSingleTextBlock =
    block.content.length === 1 && block.content[0].__typename === 'StructuredTextBlockRecord';

  const hasTextAndImage =
    block.content.length === 2 &&
    block.content[0].__typename === 'StructuredTextBlockRecord' &&
    block.content[1].__typename === 'ImageBlockRecord';

  const flipItemOrder = hasTextAndImage && isMobile;

  const content = (
    <Container size={hasTwoItems ? 'xl' : hasSingleTextBlock ? 'lg' : 'fluid'}>
      <Flex
        flexWrap="wrap"
        alignItems="center"
        flexDirection={flipItemOrder ? 'column-reverse' : 'row'}
        mx={hasTwoItems ? -4 : 0}
      >
        {block.content.map((item: any, index: number) => (
          <Box
            key={`${item.id}-${index}`}
            width={hasTwoItems ? [1, 1, 1 / 2] : 1}
            px={hasTwoItems ? 4 : 0}
            mb={[5, 5, 0]}
          >
            <BlockRenderer block={item as unknown as CMSBlock} overrides={overrides} />
          </Box>
        ))}
      </Flex>
    </Container>
  );

  return block.anchor ? (
    <Anchor name={block.anchor} id={block.anchor}>
      {content}
    </Anchor>
  ) : (
    content
  );
};

export default ContentLayout;
